import * as React from "react";
import { useEffect } from "react";
import { useUser } from "../../UserContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import AppTheme from "../../Theme/AppTheme";
import ColorModeSelect from "../../Theme/ColorModeSelect";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const proxy = process.env.REACT_APP_PROXY;

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
}));

export default function SignIn(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [inputs, setInputs] = React.useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const { setUser } = useUser();

  useEffect(() => {
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="));
    if (token) {
      setIsLoggedIn(true);
      navigate("/"); // Redirect to main page
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!emailError && !passwordError) {
      try {
        const res = await axios.post(`${proxy}/auth/login`, inputs);
        if (res.status === 200) {
          const {
            Name: name,
            Email: email,
            Role_Name: role,
            Permissions: permissions,
          } = res.data.user;
          const token = res.data.token;

          // Set the JWT token in cookies
          const expirationTime = rememberMe ? 31536000 : 0;
          document.cookie = `authToken=${token}; max-age=${expirationTime}; path=/`;

          // Update UserContext with fetched data
          setUser({ name, email, role, permissions });

          // Redirect user after successful login
          navigate("/");
        }
      } catch (error) {
        // Handle error responses
        if (error.response) {
          if (error.response.status === 401) {
            setPasswordError(true);
            setPasswordErrorMessage("Invalid password.");
          } else if (error.response.status === 404) {
            setEmailError(true);
            setEmailErrorMessage("User not found.");
          }
        }
      }
    }
  };

  const onChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const validateInputs = (event) => {
    event.preventDefault();
    let isValid = true;

    if (!/\S+@\S+\.\S+/.test(inputs.email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (inputs.password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (isValid) {
      handleSubmit(event);
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      {!isLoggedIn && (
        <SignInContainer direction="column" justifyContent="space-between">
          <ColorModeSelect
            sx={{ position: "fixed", top: "1rem", right: "1rem" }}
          />
          <Card variant="outlined">
            <Typography component="h1" variant="h4">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={validateInputs}
              noValidate
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  onChange={onChange}
                  error={emailError}
                  helperText={emailErrorMessage}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="your@email.com"
                  required
                  fullWidth
                />
              </FormControl>
              <FormControl>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Link component="button" onClick={() => setOpen(true)}>
                    Forgot your password?
                  </Link>
                </Box>
                <TextField
                  onChange={onChange}
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="••••••"
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={() => setShowPassword((prev) => !prev)} // Toggle visibility
                        sx={{
                          padding: 0,
                          backgroundColor: "transparent",
                          "&:hover": { backgroundColor: "transparent" },
                        }} // Remove default padding
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </Button>
                    ),
                  }}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe} // Control the checked state
                    value="remember"
                    color="primary"
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                }
                label="Remember me"
              />
              <ForgotPassword open={open} handleClose={() => setOpen(false)} />
              <Button type="submit" fullWidth variant="contained">
                Sign in
              </Button>
            </Box>
          </Card>
        </SignInContainer>
      )}
    </AppTheme>
  );
}
