import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import UseTable from "../../components/assests/UseTable";
import InitiativesForm from "./InitiativesForm";
import AppTheme from "../../Theme/AppTheme";
import Notification from "../../components/assests/Notification";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import axios from "axios";

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth",
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1, // Allow it to grow
  marginRight: theme.spacing(1),
  minWidth: 400, // Prevent overflow when the container is small
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "Initiative_ID", label: "ID" },
  { id: "Objective_ID", label: "Objective" },
  { id: "Initiative_Name", label: "Initiative" },
  { id: "Description", label: "Description" },
  { id: "Department", label: "Department" },
  { id: "Start_Date", label: "Start Date" },
  { id: "End_Date", label: "End Date" },
  { id: "Status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

function Initiatives({ drawerWidth, ...props }) {
  const [initativeRecords, setinItativeRecords] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("InitiativeId");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [initiativeIdToDelete, setInitiativeIdToDelete] = useState(null);
  const [reloadInitiatives, setReloadInitiatives] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { TblContainer } = UseTable(initativeRecords, headCells);

  const showNotification = (notification) => {
    setNotify(notification);
  };

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        setDepartments(response.data);
      } catch (error) {}
    };
    fetchDepartments();
  }, []);

  const getObjectivetName = (objectiveId) => {
    const objective = objectives.find(
      (objective) => objective.Strategic_Objectives_ID === objectiveId
    );
    return objective ? objective.Description : "Unknown";
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find(
      (dep) => dep.Department_ID === departmentId
    );
    return department ? department.Department_Name : "Unknown";
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const fetchInitiatives = async () => {
    try {
      const response = await axios.get(`${proxy}/initiatives/getAllInitiative`);
      setinItativeRecords(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch plans",
        type: "error",
      });
    }
  };

  const sortedRecords = [...initativeRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((initiative) => {
      return (
        (initiative.Description &&
          initiative.Description.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Initiative_Name &&
          initiative.Initiative_Name.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Objective_ID &&
          getObjectivetName(initiative.Objective_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Department_ID &&
          getDepartmentName(initiative.Department_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Status &&
          initiative.Status.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Start_Date &&
          formatDate(initiative.Start_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.End_Date &&
          formatDate(initiative.End_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Initiative_ID &&
          initiative.Initiative_ID.toString().includes(searchInput)) ||
        (initiative.End_Date &&
          formatDate(initiative.End_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase()))
      );
    });
  }, [sortedRecords, searchInput]);

  useEffect(() => {
    fetchInitiatives();
  }, [reloadInitiatives]);

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (initiativeIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/initiatives/deleteInitiative/${initiativeIdToDelete}`
        );
        if (response && response.data) {
          setReloadInitiatives((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete plan",
          type: "error",
        });
      } finally {
        setInitiativeIdToDelete(null); // Reset after delete
      }
    }
  };

  const confirmDeleteObjective = (Initiative_ID) => {
    setInitiativeIdToDelete(Initiative_ID);
    setOpenConfirmDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    console.log(item);
    setOpenPopup(true);
  };

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <PageHeader
          title="Initiative Page"
          subTitle="Define the Initiatives"
          icon={<DownloadDoneIcon fontSize="large" />}
        />

        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-initiative-input"
              placeholder="Search Initiatives"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>

          <NewButton>
            <Controls.Button
              text="Add New Initiative"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopup(true);
              }}
              sx={{ minWidth: "300px" }}
            />
          </NewButton>
        </Toolbar>

        <TblContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  sx={{ bgcolor: "#f4f6fa" }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item) => {
              // Split the Department_IDs into an array of department IDs
              const departmentIds = item.Department_IDs.split(",").map((id) =>
                parseInt(id, 10)
              ); // Fetch the department names for each ID and log the results
              const departmentNames = departmentIds.map((id) => {
                const departmentName = getDepartmentName(id);
                return departmentName;
              });

              // Join the department names into a string if there are multiple
              const departmentString = departmentNames.join(", ");

              return (
                <TableRow hover key={item.Initiative_ID}>
                  <TableCell align="center">{item.Initiative_ID}</TableCell>
                  <TableCell align="center">
                    {getObjectivetName(item.Objective_ID)}
                  </TableCell>
                  <TableCell align="center">{item.Initiative_Name}</TableCell>
                  <TableCell align="center">{item.Description}</TableCell>
                  <TableCell align="center">
                    {departmentString || "Unknown"}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.Start_Date)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.End_Date)}
                  </TableCell>
                  <TableCell align="center">{item.Status}</TableCell>
                  <TableCell align="center">
                    <Controls.ActionButton onClick={() => openInPopup(item)}>
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => confirmDeleteObjective(item.Initiative_ID)}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
      </ContentContainer>

      <Popup
        title="Initiative Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <InitiativesForm
          recordForEdit={recordForEdit}
          showNotification={showNotification}
          onSubmit={() => setReloadInitiatives((prev) => !prev)}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        DialogName="Delete Initiative Confirmation"
        DialogText="Are you sure you want to delete this Initiative?"
        onConfirm={() => {
          handleConfirmDelete();
          showNotification({
            isOpen: true,
            message: "Initiative deleted successfully",
            type: "success",
          });
        }}
        onClose={() => setOpenConfirmDialog(false)}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </AppTheme>
  );
}

export default Initiatives;
