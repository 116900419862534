import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const proxy = process.env.REACT_APP_PROXY;

const planFValues = {
  Strategic_Plan_ID: 0,
  Strategic_Objectives_ID: 0,
  Description: "",
};

export default function ObjectiveForm(props) {
  const { recordForEdit, onSubmit, showNotification } = props;
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || planFValues,
    true
  );

  useEffect(() => {
    if (recordForEdit) {
      setValues({
        Strategic_Plan_ID: recordForEdit.Strategic_Plan_ID,
        Strategic_Objectives_ID: recordForEdit.Strategic_Objectives_ID,
        Description: recordForEdit.Description,
      });
    } else {
      resetForm();
    }
  }, [recordForEdit, setValues]);

  useEffect(() => {
    const fetchplans = async () => {
      try {
        const response = await axios.get(`${proxy}/plan/getAllPlans`);
        setPlans(response.data.data);
      } catch (error) {}
    };
    fetchplans();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const ObjectiveData = {
      Strategic_Objectives_ID: recordForEdit
        ? recordForEdit.Strategic_Objectives_ID
        : null,
      Strategic_Plan_ID: values.Strategic_Plan_ID, // Use values here to reflect updated selections
      Description: values.Description,
    };

    try {
      if (recordForEdit) {
        await axios.put(
          `${proxy}/objective/updateObjective/${recordForEdit.Strategic_Objectives_ID}`,
          ObjectiveData
        );
      } else {
        await axios.post(`${proxy}/objective/saveObjective`, ObjectiveData);
      }
      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "Objective updated successfully!"
          : "Objective submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false);
    } catch (error) {
      if (error.response?.status === 409) {
        showNotification({
          isOpen: true,
          message: "Objective already exists.",
          type: "warning",
        });
      } else {
        showNotification({
          isOpen: true,
          message: "Failed to submit Objective data.",
          type: "error",
        });
      }
    } finally {
      setLoading(false); // Set loading back to false when done
    }
  };

  const handleReset = () => {
    resetForm();
  };

  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form
        onSubmit={handleSubmit}
        sx={{
          width: "80%", // Set the form width to 80% of its parent
          maxWidth: "600px", // Set a max width (e.g., 600px) to avoid overly large forms on wide screens
          margin: "0 auto", // Center the form
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" required>
              <InputLabel>Strategic Plan</InputLabel>
              <Select
                placeholder="Strategic Plan"
                name="Strategic_Plan_ID"
                value={
                  plans.some(
                    (plan) =>
                      plan.Strategic_Plan_ID === values.Strategic_Plan_ID
                  )
                    ? values.Strategic_Plan_ID
                    : ""
                }
                onChange={handleInputChange}
                sx={{ minWidth: 500 }}
              >
                {plans.map((plan) => (
                  <MenuItem
                    key={plan.Strategic_Plan_ID}
                    value={plan.Strategic_Plan_ID}
                  >
                    {plan.strategic_plan_Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Controls.Input
              required
              name="Description"
              placeholder="Objective Description"
              value={values.Description}
              onChange={handleInputChange}
              error={errors.strategic_plan_Description}
              fullWidth
              autoFocus
              margin="normal"
              sx={{
                height: 200, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={8}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
