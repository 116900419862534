import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Controls from "./Controls";
import AppTheme from "../../Theme/AppTheme";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  DialogText,
  DialogName,
}) => {
  return (
    <AppTheme>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{DialogName}</DialogTitle>
        <DialogContent>
          <DialogContentText>{DialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Controls.Button onClick={onClose} text="Cancel" color="default" />
          <Controls.Button onClick={onConfirm} text="Delete" color="primary" />
        </DialogActions>
      </Dialog>
    </AppTheme>
  );
};

export default ConfirmationDialog;
