import * as React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

function Notification(props) {
  const { notify, setNotify } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return; // Prevent close on click-away
    setNotify({ ...notify, isOpen: false });
  };

  return (
    <Snackbar
      open={notify.isOpen}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert
        severity={notify.type}
        sx={{ backgroundColor: "#edf7ec", borderColor: "#edf7ec" }}
      >
        {notify.message}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
