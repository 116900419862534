import React from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import Controls from "./Controls";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

// Styled components
const DialogWrapper = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(2),
  position: "absolute",
  top: theme.spacing(5),
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingRight: "0px",
}));

const CloseButtonContainer = styled("div")({
  display: "flex",
});

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;

  return (
    <DialogWrapper open={openPopup} maxWidth="lg">
      <StyledDialogTitle>
        <CloseButtonContainer>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Controls.ActionButton
            color="secondary"
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </CloseButtonContainer>
      </StyledDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </DialogWrapper>
  );
}
