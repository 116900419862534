import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import axios from "axios";
import Notification from "../../components/assests/Notification";
import { useNavigate } from "react-router-dom";

const proxy = process.env.REACT_APP_PROXY;

const ForgotPassword = ({ open, handleClose }) => {
  const [email, setEmail] = useState(""); // State for email
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${proxy}/auth/forgotPassword`, {
        email,
      });

      if (response.status === 200) {
        setNotify({
          isOpen: true,
          message: "A new email has been sent to you, please check your inbox.",
          type: "success",
        });
        setTimeout(() => {
          navigate("/login");
          handleClose();
          setEmail("");
        }, 2000);
      }
    } catch (error) {
      console.error("Error sending forgot password request:", error);
      setNotify({
        isOpen: true,
        message: "Failed to send email. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit, // Handle form submission here
        }}
      >
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <DialogContentText>
            Enter your account&apos;s email address, and we&apos;ll send you a
            link to reset your password.
          </DialogContentText>
          <OutlinedInput
            autoFocus
            required
            margin="dense"
            id="email"
            name="email"
            label="Email address"
            placeholder="Email address"
            type="email"
            fullWidth
            value={email} // Bind the input value to the state
            onChange={(e) => setEmail(e.target.value)} // Update the state on input change
          />
        </DialogContent>
        <DialogActions sx={{ pb: 3, px: 3 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
