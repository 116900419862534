import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const proxy = process.env.REACT_APP_PROXY;

const KPIFValues = {
  KPI_Id: 0,
  KPI_Name: "",
  Target_Value: "",
  Actual_Value: "",
  Objective_Id: "",
  Start_Date: null,
  End_Date: null,
};
dayjs.extend(utc);

export default function KPIForm(props) {
  const { recordForEdit, onSubmit, showNotification } = props;
  const [loading, setLoading] = useState(false);
  const [objectives, setObjectives] = useState([]);

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || KPIFValues,
    true
  );

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  useEffect(() => {
    if (recordForEdit) {
      setValues({
        KPI_Id: recordForEdit.KPI_Id,
        KPI_Name: recordForEdit.KPI_Name,
        Actual_Value: recordForEdit.Actual_Value,
        Target_Value: recordForEdit.Target_Value,
        Objective_Id: recordForEdit.Objective_Id,
        Start_Date: recordForEdit.Start_Date
          ? dayjs(recordForEdit.Start_Date)
          : null,
        End_Date: recordForEdit.End_Date ? dayjs(recordForEdit.End_Date) : null,
      });
    } else {
      resetForm();
    }
  }, [recordForEdit, setValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const KPIData = {
      KPI_Id: recordForEdit ? recordForEdit.KPI_Id : null,
      KPI_Name: values.KPI_Name,
      Actual_Value: values.Actual_Value,
      Target_Value: values.Target_Value,
      Objective_Id: values.Objective_Id,
      Start_Date: values.Start_Date
        ? dayjs(values.Start_Date).format("YYYY-MM-DD")
        : "",
      End_Date: values.End_Date
        ? dayjs(values.End_Date).format("YYYY-MM-DD")
        : "",
    };

    try {
      if (recordForEdit) {
        console.log("KPI data to update:", KPIData);
        await axios.put(
          `${proxy}/kpi/updateKPI/${recordForEdit.KPI_Id}`,
          KPIData
        );
      } else {
        await axios.post(`${proxy}/kpi/saveKPI`, KPIData);
      }

      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "KPI updated successfully!"
          : "KPI submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false);
    } catch (error) {
      if (error.response.status === 409) {
        showNotification({
          isOpen: true,
          message: "KPI already exists.",
          type: "warning",
        });
      } else {
        showNotification({
          isOpen: true,
          message: "Failed to submit KPI data.",
          type: "error",
        });
      }
    } finally {
      setLoading(false); // Set loading back to false when done
    }
  };

  const handleReset = () => {
    resetForm();
  };

  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Objective</InputLabel>
              <Select
                placeholder="Objective"
                name="Objective_Id"
                value={values.Objective_Id || ""}
                onChange={(e) =>
                  setValues({ ...values, Objective_Id: e.target.value })
                }
                sx={{ minWidth: 900 }}
                margin="normal"
              >
                {objectives.map((objective) => (
                  <MenuItem
                    key={objective.Strategic_Objectives_ID}
                    value={objective.Strategic_Objectives_ID}
                  >
                    {objective.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Controls.Input
              required
              name="KPI_Name"
              placeholder="KPI Name"
              value={values.KPI_Name}
              onChange={handleInputChange}
              error={errors.KPI_Name}
              fullWidth
              autoFocus
              margin="normal"
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="Start_Date"
                  slotProps={{
                    textField: {
                      helperText: "Start Date",
                    },
                  }}
                  value={values.Start_Date ? dayjs(values.Start_Date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, Start_Date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="End_Date"
                  slotProps={{
                    textField: {
                      helperText: "End Date",
                    },
                  }}
                  value={values.End_Date ? dayjs(values.End_Date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, End_Date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
            </div>
            <Controls.Input
              required
              label="Target Value"
              variant="filled"
              name="Target_Value"
              placeholder="Target Value"
              value={values.Target_Value}
              onChange={handleInputChange}
              error={errors.Target_Value}
              fullWidth
              autoFocus
              margin="normal"
              type="number"
            />
            <Controls.Input
              required
              label="Actual Value"
              variant="filled"
              name="Actual_Value"
              placeholder="Actual Value"
              value={values.Actual_Value}
              onChange={handleInputChange}
              error={errors.Actual_Value}
              fullWidth
              autoFocus
              margin="normal"
              type="number"
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
