import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import AppTheme from "../../Theme/AppTheme";
import ColorModeSelect from "../../Theme/ColorModeSelect";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Notification from "../../components/assests/Notification";

const proxy = process.env.REACT_APP_PROXY;

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
}));

export default function ResetPassword(props) {
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const token = searchParams.get("token");
        const response = await axios.get(
          `${proxy}/auth/getUserByResetToken?token=${token}`
        );
        setEmail(response.data.email);
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    };

    fetchUserEmail();
  }, [proxy, searchParams]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMessage("Passwords do not match.");
      return;
    } else {
      setConfirmPasswordError(false);
      setConfirmPasswordErrorMessage("");
    }

    try {
      const token = searchParams.get("token");
      const res = await axios.put(`${proxy}/auth/resetPassword`, {
        email,
        newPassword,
        token,
      });

      if (res.status === 200) {
        setNotify({
          isOpen: true,
          message: "Password reset successful",
          type: "success",
        });
        setTimeout(() => navigate("/login"), 2000);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setPasswordError(true);
      setPasswordErrorMessage("Failed to reset password. Please try again.");
      setNotify({
        isOpen: true,
        message: "Failed to reset password. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <ColorModeSelect
          sx={{ position: "fixed", top: "1rem", right: "1rem" }}
        />
        <Card variant="outlined">
          <Typography component="h1" variant="h4">
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                value={email}
                disabled
                id="email"
                name="email"
                type="email"
                fullWidth
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="newPassword">New Password</FormLabel>
              <TextField
                onChange={(e) => setNewPassword(e.target.value)}
                error={passwordError}
                helperText={passwordErrorMessage}
                name="newPassword"
                type="text"
                id="newPassword"
                placeholder="••••••"
                required
                fullWidth
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
              <TextField
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={confirmPasswordError}
                helperText={confirmPasswordErrorMessage}
                name="confirmPassword"
                type="text"
                id="confirmPassword"
                placeholder="••••••"
                required
                fullWidth
              />
            </FormControl>
            <Button type="submit" fullWidth variant="contained">
              Reset Password
            </Button>
          </Box>
        </Card>
      </SignInContainer>
      <Notification notify={notify} setNotify={setNotify} />
    </AppTheme>
  );
}
