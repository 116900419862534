import React from "react";
import { TextField } from "@mui/material";

export default function Input(props) {
  const { name, label, value, error = null, onChange, ...other } = props;

  // Ensure only valid attributes are passed to the TextField
  const validProps = {
    variant: "outlined",
    label: label,
    name: name,
    value: value,
    onChange: onChange,
    ...other,
  };

  return (
    <TextField
      {...validProps}
      {...(error && { error: true, helperText: error })}
    />
  );
}
