import * as React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import GroupIcon from "@mui/icons-material/Group";
import { useUser } from "../../UserContext"; // Adjust the path as necessary
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import MovingIcon from "@mui/icons-material/Moving";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DashboardIcon from "@mui/icons-material/Dashboard";

const mainListItems = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  { text: "Strategic Plan", icon: <ReceiptLongIcon />, path: "/strategicPlan" },
  { text: "Objectives", icon: <EmojiObjectsIcon />, path: "/objectives" },
  { text: "Initiatives", icon: <DownloadDoneIcon />, path: "/initiatives" },
  { text: "KPI", icon: <MovingIcon />, path: "/kpi" },
  { text: "KRI", icon: <GppMaybeIcon />, path: "/kri" },
];

const secondaryListItems = [
  { text: "Users", icon: <GroupIcon />, path: "/Users" },
];

export default function MenuContent() {
  const navigate = useNavigate();
  const { user } = useUser();

  const hasManageSystemPermission =
    user?.Permissions?.includes("manage_system");

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    // <AppTheme>
    <Stack
      sx={{
        flexGrow: 1,
        p: 2,
        justifyContent: "space-between",
      }}
    >
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              display: "block",
              mb: 2,
              fontWeight: "lg",
              borderRadius: "12px",
              fontSize: "16px",
            }}
          >
            <ListItemButton
              onClick={() => handleNavigation(item.path)}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)", // Light gray hover effect
                },
                color: "#424264",
                fontStyle: "bold",
                fontSize: "1.2rem",
              }}
            >
              <ListItemIcon sx={{ color: "#424264" }}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  fontSize: "16px", // Ensure this is applied directly
                  fontWeight: "medium",
                  color: "#424264", // Ensure text color is #424264
                  "& .MuiTypography-root": {
                    fontSize: "16px", // This ensures that Typography inside ListItemText also gets the correct font size
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{
              display: "block",
              mb: 1,
              fontWeight: "lg",
              borderRadius: "12px",
              color: "#424264",
            }}
          >
            <ListItemButton
              onClick={() => handleNavigation(item.path)}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)", // Light gray hover effect
                },
              }}
            >
              <ListItemIcon sx={{ color: "#424264" }}>
                {" "}
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  fontSize: "16px", // Ensure this is applied directly
                  fontWeight: "medium",
                  color: "#424264", // Ensure text color is #424264
                  "& .MuiTypography-root": {
                    fontSize: "16px", // This ensures that Typography inside ListItemText also gets the correct font size
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
    //   </AppTheme>
  );
}
