import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import AppTheme from "../../Theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const proxy = process.env.REACT_APP_PROXY;

function KPI() {
  const [objectives, setObjectives] = useState([]);
  const [kpis, setKPIs] = useState([]);
  const [values, setValues] = useState({ Objective_Id: "" });

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        const allObjectives = response.data.data;

        // Filter objectives to include only those with associated KPIs
        const objectivesWithKPIs = await Promise.all(
          allObjectives.map(async (objective) => {
            const kpiResponse = await axios.get(
              `${proxy}/kpi/getKPIsByObjective/${objective.Strategic_Objectives_ID}`
            );
            return kpiResponse.data.data.length > 0 ? objective : null;
          })
        );

        // Filter out null values and update state
        const filteredObjectives = objectivesWithKPIs.filter(Boolean);
        setObjectives(filteredObjectives);

        // Set default to the last objective if available
        if (filteredObjectives.length > 0) {
          setValues((prevValues) => ({
            ...prevValues,
            Objective_Id:
              filteredObjectives[filteredObjectives.length - 1]
                .Strategic_Objectives_ID,
          }));
        }
      } catch (error) {
        console.error("Error fetching objectives:", error);
      }
    };

    fetchObjectives();
  }, []);

  // Fetch KPIs when an objective is selected
  useEffect(() => {
    const fetchKPIsByObjective = async () => {
      if (values.Objective_Id) {
        try {
          const response = await axios.get(
            `${proxy}/kpi/getKPIsByObjective/${values.Objective_Id}`
          );
          setKPIs(response.data.data); // Store the KPIs fetched from the backend
        } catch (error) {
          console.error("Error fetching KPIs:", error);
        }
      }
    };
    fetchKPIsByObjective();
  }, [values.Objective_Id]);

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box sx={{ width: "100%", flexGrow: 1, padding: 1 }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid size={6}>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Objective</InputLabel>
              <Select
                name="Objective_Id"
                value={values.Objective_Id || ""}
                onChange={(e) =>
                  setValues({ ...values, Objective_Id: e.target.value })
                }
                sx={{ minWidth: 600 }}
                margin="normal"
              >
                {objectives.map((objective) => (
                  <MenuItem
                    key={objective.Strategic_Objectives_ID}
                    value={objective.Strategic_Objectives_ID}
                  >
                    {objective.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {values.Objective_Id && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {kpis.map((kpi, index) => (
                <Paper
                  elevation={3}
                  sx={{
                    width: "25%",
                    height: "250px",
                    minHeight: "250px",
                    maxHeight: "250px",
                    padding: 1,
                    marginBottom: 1,
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={kpi.KPI_Id}
                    textAlign="center"
                    size={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Gauge
                      width={200}
                      height={200}
                      value={(kpi.Actual_Value / kpi.Target_Value) * 100}
                      startAngle={-110}
                      endAngle={110}
                      sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: 20,
                          transform: "translate(0px, 0px)",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                          fill: "#52b202",
                        },
                      }}
                      text={({ value }) => `${Math.round(value)}%`}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        wordWrap: "break-word",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        maxWidth: "200px",
                      }}
                    >
                      {kpi.KPI_Name}
                    </Typography>
                  </Grid>
                </Paper>
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </AppTheme>
  );
}

export default KPI;
