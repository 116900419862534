import React from "react";
import { Paper, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppTheme from "../../Theme/AppTheme";

// Styled components
const Root = styled(Paper)(({ theme }) => ({
  background: "#15b096", // Diagonal gradient
  elevation: 0,
  square: true,
  width: "100vw", // Ensure it always takes the full viewport width
  maxWidth: "100%", // In case there's any parent constraint, it ensures no overflow
  position: "relative", // If needed, set position to avoid overlapping
}));

const PageHeaderContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  marginBottom: theme.spacing(2),
  width: "100%", // Ensure container takes full width of Root
  justifyContent: "flex-start", // Optional: control alignment
}));

const PageIcon = styled(Card)(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(1),
  color: "#3c44b1",
}));

const PageTitle = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  "& .MuiTypography-subtitle2": {
    opacity: "0.7",
  },
  color: "#FFFFFF",
}));

export default function PageHeader(props) {
  const { title, subTitle, icon } = props;
  return (
    <AppTheme {...props}>
      <Root>
        <PageHeaderContainer>
          <PageIcon>{icon}</PageIcon>
          <PageTitle>
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            <Typography variant="subtitle2" component="div">
              {subTitle}
            </Typography>
          </PageTitle>
        </PageHeaderContainer>
      </Root>
    </AppTheme>
  );
}
