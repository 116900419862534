import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const proxy = process.env.REACT_APP_PROXY;

const initialFValues = {
  InitiativeId: 0,
  Initiative_Name: "",
  Description: "",
  Start_Date: null,
  End_Date: null,
  Status: "",
  Objective_ID: "",
  Department_ID: [],
};

export default function InitiativesForm(props) {
  const { recordForEdit, showNotification, onSubmit } = props;
  const [objectives, setObjectives] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    // Fetch objectives
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {
        console.error("Error fetching objectives:", error);
      }
    };
    fetchObjectives();
  }, []);

  useEffect(() => {
    // Fetch departments
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || initialFValues,
    true
  );

  useEffect(() => {
    if (recordForEdit && recordForEdit.Initiative_ID !== undefined) {
      setValues((prevValues) => ({
        ...prevValues,
        ...recordForEdit,
        Start_Date: recordForEdit.Start_Date
          ? dayjs(recordForEdit.Start_Date)
          : null,
        End_Date: recordForEdit.End_Date ? dayjs(recordForEdit.End_Date) : null,
        Department_ID: recordForEdit.Department_IDs
          ? recordForEdit.Department_IDs.split(",").map(Number)
          : [],
      }));
    } else {
      setValues(initialFValues);
    }
  }, [recordForEdit, setValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const InitiativeData = {
      InitiativeId: recordForEdit ? recordForEdit.Initiative_ID : null,
      Initiative_Name: values.Initiative_Name,
      Description: values.Description,
      Start_Date: values.Start_Date
        ? values.Start_Date.format("YYYY-MM-DD")
        : "",
      End_Date: values.End_Date ? values.End_Date.format("YYYY-MM-DD") : "",
      Status: values.Status,
      Objective_ID: values.Objective_ID,
      Department_ID: values.Department_ID,
    };

    try {
      if (recordForEdit) {
        await axios.put(
          `${proxy}/initiatives/updateInitiative/${recordForEdit.Initiative_ID}`,
          InitiativeData
        );
      } else {
        await axios.post(`${proxy}/initiatives/saveInitiative`, InitiativeData);
      }
      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "Initiatives updated successfully!"
          : "Initiatives submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false);
    } catch (error) {
      console.error("Error submitting initiative data:", error); // Log error
      showNotification({
        isOpen: true,
        message: "Failed to submit Initiative data.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    resetForm(); // Ensure the form resets to initial values
    setValues(initialFValues); // Reset dropdowns and any other values
  };
  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Objective</InputLabel>
              <Select
                placeholder="Objective"
                name="Objective"
                value={values.Objective_ID || ""}
                onChange={(e) =>
                  setValues({ ...values, Objective_ID: e.target.value })
                }
                sx={{ minWidth: 500 }}
                margin="normal"
              >
                {objectives.map((objective) => (
                  <MenuItem
                    key={objective.Strategic_Objectives_ID}
                    value={objective.Strategic_Objectives_ID}
                  >
                    {objective.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel
                htmlFor="demo-multiple-checkbox"
                shrink={
                  Array.isArray(values.Department_ID) &&
                  values.Department_ID.length > 0
                }
              >
                Departments
              </InputLabel>

              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="Department"
                multiple
                value={
                  Array.isArray(values.Department_ID)
                    ? values.Department_ID
                    : []
                }
                onChange={(e) =>
                  setValues({ ...values, Department_ID: e.target.value })
                }
                sx={{ minWidth: 500 }}
                margin="normal"
                input={<OutlinedInput label="Department" />}
                renderValue={(selected) => {
                  return selected
                    .map(
                      (id) =>
                        departments.find((dept) => dept.Department_ID === id)
                          ?.Department_Name
                    )
                    .join(", ");
                }}
                MenuProps={MenuProps}
              >
                {departments.map((department) => (
                  <MenuItem
                    key={department.Department_ID}
                    value={department.Department_ID}
                  >
                    <Checkbox
                      checked={values.Department_ID.includes(
                        department.Department_ID
                      )}
                    />
                    <ListItemText primary={department.Department_Name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Controls.Input
              required
              name="Initiative_Name"
              placeholder="Initiative Name"
              value={values.Initiative_Name}
              onChange={handleInputChange}
              error={errors.Initiative_Name}
              fullWidth
              autoFocus
              margin="normal"
            />
            <Controls.Input
              required
              name="Description"
              placeholder="Description"
              value={values.Description}
              onChange={handleInputChange}
              error={errors.Description}
              fullWidth
              margin="normal"
              type="string"
              sx={{
                height: 200, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={8}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="Start_Date"
                  slotProps={{
                    textField: {
                      helperText: "Start Date",
                    },
                  }}
                  value={values.Start_Date ? dayjs(values.Start_Date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, Start_Date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="End_Date"
                  slotProps={{
                    textField: {
                      helperText: "End Date",
                    },
                  }}
                  value={values.End_Date ? dayjs(values.End_Date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, End_Date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
            </div>

            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                placeholder="Status"
                name="Status"
                value={values.Status || ""} // Bind to `values.Status`
                onChange={
                  (e) => setValues({ ...values, Status: e.target.value }) // Update status in values
                }
              >
                <MenuItem value="Not started">Not started</MenuItem>
                <MenuItem value="In progress">In progress</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Delayed">Delayed</MenuItem>
                <MenuItem value="Canceled">Canceled</MenuItem>
              </Select>
            </FormControl>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
